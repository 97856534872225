
export default class GlobalInfo {
    //---------------------------------域名服务器配置--------------------------------//
  //  static baseUrl = 'http://192.168.1.18:7070';               //前端网址路径，若部署服务器，需做相应修改
   // static backUrl = 'http://192.168.1.18:9090/admin/';   //后端网址路径，若部署服务器，需做相应修改
    static baseUrl = 'https://survey.limitui.com';               //前端网址路径，若部署服务器，需做相应修改
    static backUrl = 'https://survey.limitui.com/admin/';   //后端网址路径，若部署服务器，需做相应修改
    static email = '';                       //编辑或管理员邮箱地址
    static domain = 'survey.limitui.com';                          //底边栏显示的域名
    static publicBackUrl = '/ip/';   //公开后端网址路径，主要用于获取用户IP地址

    //---------------------------------项目内部参数--------------------------------//
    static previewUrl = [
        'preview',
        'preview_test',
        'preview_vote',
        'preview_form',
        'preview_hate'
    ];
    static fillUrl = [
        'fill',
        'fill_test',
        'fill_vote',
        'fill_form',
        'fill_hate'
    ];
}
